/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useNavigate } from "react-router-dom";
import { api, randRange } from './../../helpers';
import './products.css';
import { ICategory, IProduct } from '../../interfaces';
import moment from 'moment';

export const Products = () => {
  const [render, setRender] = React.useState(false);
  const [products, setProducts] = React.useState<IProduct[]>([]);
  const [categories, setCategories] = React.useState<ICategory[]>([]);

  const navigate = useNavigate();
  
  const addProduct = () => { 
    const p = [...products];
    const sizes = {"35": 0, "36": 0, "37": 0, "38": 0, "39": 0, "40": 0, };
    
    p.push({ reference: '', category: categories[0].name, sizes, priceUsd: 0, createdAt: moment.utc().toDate()});
    setProducts(p); 
    console.log(`Hello`, p)
  }

  React.useEffect(() => {
    let ignore = false;
    try {
      const r = api.call(`${process.env.REACT_APP_API_URL}/get-categories`);
      r?.request.then((req: any) => {
        if (ignore) { return; }
        setCategories(req.categories);
        const r = api.call(`${process.env.REACT_APP_API_URL}/get-products`);
        r?.request.then((req: any) => {
          setProducts(req.products);
          setRender(true); 
        });

        r?.request.catch((e: any) => {  console.error(e); });
      });

      r?.request.catch((e: any) => {  console.error(e); });
    } catch (e) {
      console.error(e);
    }
    return () => {
      ignore = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !render ? <></> : <>
    <div className='home-content'>
      <h3 onClick={() => navigate('/')}>Products</h3> 
      <div>
        <button onClick={() => addProduct()}>Add</button>
      </div>
      {products.map((it) => <Product key={`${it.reference}`} product={it} categories={categories} />)} 
    </div>
  </>;
}

const Product = (props: { product: IProduct; categories: ICategory[] }) => {
  const [product, setProduct] = React.useState<IProduct>(JSON.parse(JSON.stringify(props.product)));
  const [category, setCategory] = React.useState(props.product.category || '');
  const [isNew, setIsNew] = React.useState(props.product.reference === '');
  const [sizes, setSizes] = React.useState<{ [size: string]: number}>(props.product.sizes);
  const [reference, setReference] = React.useState(props.product.reference);
  const [priceUsd, setPriceUsd] = React.useState(`${props.product.priceUsd}`);
  const [priceUsdPrevious, setPricePreviousUsd] = React.useState(`${props.product.priceUsdPrevious}`);

  const cloneAndSetProduct = (key: string, value: any) => {
    const pr = JSON.parse(JSON.stringify(product));
    pr[key] = value;
    setProduct(pr);
    // console.log(value);
  }
  const updateProduct = () => {
    try {
      console.log(`Updating product ${reference}`);
      const r = api.call(`${process.env.REACT_APP_API_URL}/admin-product-update`, { reference, priceUsd, priceUsdPrevious, sizes: product.sizes, category });
      r?.request.then((req: any) => {
        setSizes(req.sizes);
        setPriceUsd(req.priceUsd);
        setIsNew(false);
      });
    } catch (e) {
      console.error(e);
    }
  }
  const deleteProduct = () => {
    try {
      if (isNew) { return window.location.reload(); }
      const r = api.call(`${process.env.REACT_APP_API_URL}/admin-product-delete`, { reference });
      r?.request.then((req: any) => {
        window.location.reload();
      });
    } catch (e) {
      console.error(e);
    }
  }
  const addSize = () => {
    const sz = { ...product.sizes };
    sz[`${randRange(1000,9999)}`] = 0;
    setSizes(sz);
    cloneAndSetProduct('sizes', sz);
  }
  const changeSize = (prevSizeName: string, newSizeName: string) => {
    const sz = { ...product.sizes };
    sz[`${newSizeName}`] = sizes[`${prevSizeName}`] ?? 0;
    delete sz[`${prevSizeName}`];
    cloneAndSetProduct('sizes', sz);
  }
  const changeSizeQuantity = (s: string, q: number) => {
    const sz = { ...product.sizes };
    sz[`${s}`] = q ?? 0;
    cloneAndSetProduct('sizes', sz);
  }
  const removeSize = (size: string) => {
    const sz = { ...product.sizes };
    delete sz[`${size}`];
    cloneAndSetProduct('sizes', sz);
    setSizes(sz);
  }
  return <table className='tableProduct'>
    <tbody>
      <tr>
        <td>Reference</td>
        <td>
          { isNew
            ? <input type={'text'} value={reference} placeholder='Reference' onChange={(e) => { setReference(e.currentTarget.value); } } />
            : reference 
          }
        </td>
      </tr>
      <tr>
        <td>Category</td>
        <td>
          <select value={category} onChange={(e) => setCategory(e.currentTarget.value)}>
            {props.categories.map(it => <option key={`cat${it.name}`} value={it.name} label={it.name} /> )}
          </select>
        </td>
      </tr>
      <tr>
        <td>
          Sizes <br />
          <button onClick={() => addSize()}>Add size</button>
        </td>
        <td>
          <table>
            <tbody>
              <tr>
                <td>Key</td>
                <td>Quantity</td>
                <td>&nbsp;</td>
              </tr>
              { Object.keys(sizes).sort().map((s) => 
                <Size 
                  key={`${reference}-size-${s}`} 
                  reference={reference} 
                  size={s} 
                  quantity={sizes[s]} 
                  onUpdateSize={(prevName, newName) => { changeSize(prevName, newName) }}
                  onUpdateQuantity={(size, quantity) => { changeSizeQuantity(size, quantity) }}
                  onRemove={(size) => {removeSize(size)}}
                />) }
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Previous Price (usd): </td>
        <td>
          <input type={'text'} value={priceUsdPrevious} placeholder='Previous Price' onChange={(e) => { setPricePreviousUsd(e.currentTarget.value); }} />
        </td>
      </tr>
      <tr>
        <td>Price (usd): </td>
        <td>
          <input type={'text'} value={priceUsd} placeholder='Price' onChange={(e) => { setPriceUsd(e.currentTarget.value); }} />
        </td>
      </tr>
      <tr>
        <td colSpan={2} className='actions'>
          <button className='btn' onClick={() => updateProduct()}>Save</button>
          <button className='btn' style={{marginTop: 8}} onClick={() => deleteProduct()}>Delete</button>
        </td>
      </tr>
    </tbody>
  </table>;
}

const Size = (props: { reference: string; size: string; quantity: number, onUpdateSize: (prevName: string, newName: string) => unknown, onUpdateQuantity: (size: string, quantity: number) => unknown, onRemove: (size: string) => unknown }) => {
  const [prevSize, setPrevSize] = React.useState(props.size);
  const [size, setSize] = React.useState(props.size);
  const [quantity, setQuantity] = React.useState(props.quantity);
  
  React.useEffect(() => { setPrevSize(size); props.onUpdateSize(prevSize, size); }, [size]);
  React.useEffect(() => { props.onUpdateQuantity(size, quantity); }, [quantity]);

  return <tr>
    <td><input type={'text'} value={size} onChange={(e) => setSize(e.currentTarget.value)} /></td>
    <td><input type={'number'} value={quantity} onChange={(e) => setQuantity(Number(e.currentTarget.value))} /></td>
    <td><div style={{margin: '0 12px', cursor: 'pointer'}} onClick={() => props.onRemove(size)}><i className='fa-solid fa-xmark' /></div></td>
  </tr>
}