import React from 'react';
import { useNavigate } from "react-router-dom";
import './home.css';

function Home() {
  const navigate = useNavigate();
  return (
    <div className='home-content'>
      <h3 onClick={() => navigate('/products')}>Products</h3>
      <h3 onClick={() => navigate('/categories')}>Categories</h3>
    </div>
  );
}

export default Home;
