import React from 'react';
import './app.css';
import * as request from 'superagent'; 
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import Login from './components/login/login';
import Home from './components/home/home';
import { Products } from './components/products/products';
import { Categories } from './components/categories/categories';

function App() {
  const [checking, setChecking] = React.useState(true);
  const [loggedIn, setLoggedIn] = React.useState(false);

  const router = createHashRouter([
    { path: '/', element: <Home /> },
    { path: '/products', element: <Products /> },
    { path: '/categories', element: <Categories /> },
  ]);
  const init = async () => {
    const res = (await request.post(`${process.env.REACT_APP_API_URL}/admin-login`).withCredentials().send({cookiecheck: true}))?.body;
    setChecking(false);
    if (!res.success) { return; }
    setLoggedIn(!!res.success);
  }
  React.useEffect(() => {
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return checking ? <>Loading</> : (
    <div className="app">
      {!loggedIn 
        ? <Login onLoggedIn={() => setLoggedIn(true)} />
        : <> 
          <RouterProvider router={router} />
        </> }
    </div>
  );
}

export default App;
