import React from 'react';
import './login.css';
import * as request from 'superagent';

function Login(props: { onLoggedIn: () => unknown }) {
  const [password, setPassword] = React.useState('');

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    const payload = {
      password,
    }
    const res = (await request.post(`${process.env.REACT_APP_API_URL}/admin-login`).withCredentials().send(payload))?.body;
    if (!res.success) { return alert('Incorrect password'); }
    props.onLoggedIn();
  }
  return (
    <form className="login" onSubmit={(e) => onSubmit(e)}>
      <h2>Hello, please login</h2>
      <input type={'password'} value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
      <button className='btn-login' type='submit'>Login</button>
    </form>
  );
}

export default Login;
