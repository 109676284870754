/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useNavigate } from "react-router-dom";
import { api } from './../../helpers';
import './categories.css';
import { ICategory } from '../../interfaces';
import moment from 'moment';

export const Categories = () => {
  const [render, setRender] = React.useState(false);
  const [categories, setCategories] = React.useState<ICategory[]>([]);

  const navigate = useNavigate();
  
  const addProduct = () => { 
    const p = [...categories];    
    p.push({ name: '', createdAt: moment.utc().toDate()});
    setCategories(p);
  }

  React.useEffect(() => {
    let ignore = false;
    try {
      const r = api.call(`${process.env.REACT_APP_API_URL}/get-categories`);
      r.request.then((req: any) => {
        if (ignore) { return; }
        setCategories(req.categories);
        setRender(true);
      });
    } catch (e) {
      console.error(e);
    }
    return () => {
      ignore = true;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !render ? <></> : <>
    <div className='home-content'>
      <h3 onClick={() => navigate('/')}>Home</h3> 
      <div>
        <button onClick={() => addProduct()}>Add</button>
      </div>
      {categories.map((it) => <Category key={`cat-${it.name}`} category={it} />)} 
    </div>
  </>;
}

const Category = (props: { category: ICategory }) => {
  const [previousName, setPreviousName] = React.useState(props.category.name);
  const [name, setName] = React.useState(props.category.name);
  const [isNew, setIsNew] = React.useState(props.category.name === '');

  const updateCategory = () => {
    try {
      console.log(`Updating product ${name}`);
      const r = api.call(`${process.env.REACT_APP_API_URL}/admin-category-update`, { name, previousName });
      r?.request.then((req: any) => {
        setName(req.name);
        setPreviousName(req.name);
        setIsNew(false);
      });
    } catch (e) {
      console.error(e);
    }
  }
  const deleteCategory = () => {
    try {
      if (isNew) { return window.location.reload(); }
      const r = api.call(`${process.env.REACT_APP_API_URL}/admin-category-delete`, { name: previousName });
      r?.request.then((req: any) => {
        window.location.reload();
      });
    } catch (e) {
      console.error(e);
    }
  }
  return <table className='tableProduct'>
    <tbody>
      <tr>
        <td>Name</td>
        <td>
          <input type={'text'} value={name} placeholder='Name' onChange={(e) => { setName(e.currentTarget.value); } } />
        </td>
      </tr>
      <tr>
        <td colSpan={2} className='actions'>
          <button className='btn' onClick={() => updateCategory()}>Save</button>
          <button className='btn' style={{marginTop: 8}} onClick={() => deleteCategory()}>Delete</button>
        </td>
      </tr>
    </tbody>
  </table>;
}
