import * as api from './api';

export { api };


export const trace = (...args: any) => { console?.log(...args); };
export const log = trace;
export const range = (size: number, startAt: number = 0): ReadonlyArray<number> =>  Array.from(Array(size).keys()).map(i => i + startAt);

export const isAlphaNumeric = (x: any) => {
  return /^[a-z0-9]+$/i.test(x);
};
export const isAlpha = (x: any) => {
  return /^[a-z]+$/i.test(x); 
};
export const isInteger = (n: any) => Number(n) === parseInt(n, undefined);
export function isNumeric(str: any) {
  if (str == null) {
    return false;
  }
  const regx = /^[-+]?\d*\.?\d+(?:[eE][-+]?\d+)?$/;
  return regx.test(str);
}

export const radiansToDegrees = (radians: number) => radians * (180 / Math.PI);
export const degreesToRadians = (degrees: number) => degrees * (Math.PI / 180);

export const wrap = (n: number, reel: Array<unknown>) => n % reel.length;
export function randRange(min: number, max: number) { return Math.floor(Math.random() * (max - min + 1)) + min; }

export function getWeightedResult(choiceWeight: number[]) {
  let sumOfWeight = 0;
  const numChoices = choiceWeight.length;
  for (let i = 0; i < numChoices; i++) {
    sumOfWeight += choiceWeight[i];
  }
  let rnd =  randRange(0, sumOfWeight - 1);
  for (let i = 0; i < numChoices; i++) {
    if (rnd < choiceWeight[i]) {
      return i;
    }
    rnd -= choiceWeight[i];
  }
  console.log('getWeightedResult > should never get here');
}
export const randomString = (n = 5) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  range(n).forEach(() => text += possible.charAt(Math.floor(Math.random() * possible.length)));
  return text;
};
export const delayedCall = (time: number, callback: () => unknown) => {
  const t = setTimeout(callback, time);
  return {
    time: t,
    stop: () => clearTimeout(t),
  }
}
export const hold = (time = 1000) => {
  return new Promise<void>((resolve) => { delayedCall(time, () => resolve()); });
};

export const holdObj = (time = 1000) => {
  let resolve: any;
  let timeout: any;
  const destroy = () => {
    clearTimeout(timeout);
    resolve();
  }
  const promise = new Promise((res) => {
    resolve = res;
    timeout = setTimeout(() => destroy(), time);
  });
  return {
    promise,
    destroy,
  };
};
// shuffleInPlace
export const shuffleInPlace = (a: Array<any>) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};
